// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ED2A4D", // Red color
      background: "#FDEAED",
    },
    secondary: {
      main: "#000000", // black color
      grey: "#797979",
    },
    background: {
      default: "#fff", // White color
    },
  },
  typography: {
    fontFamily:
      'Spline Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
});

export default theme;
