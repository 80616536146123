// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "./components/Theme";
import GoogleAnalytics from './GoogleAnalytics';

const rootElement = document.getElementById("root");

const setPrimaryColorCSSVariable = () => {
  const primaryColor = theme.palette.primary.main;
  rootElement.style.setProperty("--primary-color", primaryColor);
};

setPrimaryColorCSSVariable();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GoogleAnalytics trackingId="G-KYWRBLGLL5" />
      <App />
      <ToastContainer />
    </ThemeProvider>
  </React.StrictMode>,
  rootElement
);
