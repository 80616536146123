import apis from '../index'


export const getMediaAPI = async (payload) => {
    try {
        // Call your login API endpoint here
        const response = await apis.get('media', payload);
        const data = await response;
        // Return data or handle response as needed
        return data;
    } catch (error) {
        // Handle errors appropriately
        console.error('Error during fetching media:', error);
        throw error;
    }
}


export const getPagesAPI = async (payload) => {
    try {
        // Call your login API endpoint here
        const response = await apis.get(`posts?filters[type]=page&paginate=1&page=1&pagination_size=12`, payload);
        const data = await response;
        // Return data or handle response as needed
        return data;
    } catch (error) {
        // Handle errors appropriately
        console.error('Error during fetching media:', error);
        throw error;
    }
}
export const getPagesDetailsAPI = async (slug) => {
    try {
        // Call your login API endpoint here
        const response = await apis.get(`posts/${slug}`);
        const data = await response;
        // Return data or handle response as needed
        return data;
    } catch (error) {
        // Handle errors appropriately
        console.error('Error during fetching media:', error);
        throw error;
    }
}