import React, { useEffect, useState } from "react";
import "./Footer.css"; // Import the CSS file
import { Link } from "react-router-dom";
import XIcon from "@mui/icons-material/X";
import { getPagesAPI } from "../../apis/services/mediaApi";

const Footer = () => {
  const [pages, setPages] = useState();


  const getPages = async () => {
    try {
      const resp = await getPagesAPI();
      setPages(resp?.data?.posts?.data);
    } catch (err) {}
  };

  useEffect(() => {
    getPages();
    const handleScrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Adds smooth scrolling behavior
      });
    };

    

    const links = document.querySelectorAll(".footer-menu a");
    links.forEach((link) => {
      link.addEventListener("click", handleScrollToTop);
    });

    return () => {
      // Clean up the event listeners when component unmounts
      links.forEach((link) => {
        link.removeEventListener("click", handleScrollToTop);
      });
    };
  }, []);

  return (
    <div className="footer">
      <div className="wrapper p-lr">
        <div className="footer-row">
          <div className="footer-col">
            <a href="/" className="footer-logo">
              <img
                src="/assets/images/slenky-logo-big.svg"
                alt="Slenky"
                width="80px"
                height="40px"
              />
            </a>
          </div>
          <div className="footer-col">
            <ul className="footer-menu">
              {/* {pages?.map(page => <li>
                <Link to={`/go/blog/${page?.slug}`}>{page?.title}</Link>
              </li>
              )} */}
              <li>
                <Link to="/go/blog/about-slenky">About</Link>
              </li>
              <li>
                <Link to="/go/main/how-it-works">How it works</Link>
              </li>
              <li>
                <Link to="/go/blog/terms">Terms & privacy</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col np">&nbsp;</div>
          <div className="footer-col">
            <div className="social-row">
              <a
                href="https://www.facebook.com/slenkyshots/?fref=ts"
                className="social-icon"
                target="_blank"
              >
                <img
                  className="icon-size"
                  src="/assets/images/footer-facebook.svg"
                  alt="Facebook"
                />
              </a>
              <a
                href="https://twitter.com/slenkyshots"
                className="social-icon"
                target="_blank"
              >
                <XIcon className="twitter-icon" />
                {/* <img
                  className="icon-size"
                  src="/assets/images/footer-twitter.svg"
                  alt="Twitter"
                /> */}
              </a>
              <a
                href="https://www.instagram.com/slenkyshots/"
                className="social-icon"
                target="_blank"
              >
                <img
                  className="icon-size"
                  src="/assets/images/footer-instagram.svg"
                  alt="Instagram"
                />
              </a>
              <a
                href="https://www.ceop.police.uk/ceop-reporting/"
                target="_blank"
              >
                <img src="/assets/images/ceop.png" alt="Internet Safety" />
              </a>
            </div>
          </div>
        </div>
        <div className="copyright-footer">
          <p className="white-text">
            Copyright © 2024 Slenky. All Rights Reserved. | Privacy Policy
          </p>
          <p className="white-text">
            Powered by{" "}
            <a
              href="https://cubettech.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cubet
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
