import axios from "axios";
import { getHeaders, getFormHeaders } from "../helpers/commonUtils";
// import store from "../redux/store"; // Remove if not used
// import { useSelector } from "react-redux"; // Remove if not used
import useStore from "../store";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: getHeaders(),
});

apiInstance.interceptors.request.use(
  (config) => {
    const { userData } = useStore.getState(); // Access the store's state

    const token = userData?.token || localStorage.getItem("token") || "";
    config.headers["Content-Type"] = 'application/json';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
export const apiUserInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: getHeaders(),
});

apiUserInstance.interceptors.request.use(
  (config) => {
    const { userData } = useStore.getState(); // Access the store's state

    const token = userData?.token || localStorage.getItem("token") || "";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const formAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: getFormHeaders(),
});

formAxios.interceptors.request.use(
  (config) => {
    const { userData } = useStore.getState(); // Access the store's state

    const token = userData?.token || localStorage.getItem("token") || "";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiInstance;
