import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";

export default function Loader({ open, message }) {
  return (
    <div>
      <Backdrop
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(255,255,255,0.4)",
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <CircularProgress color="primary" />
          <Typography>{message}</Typography>
        </Box>
      </Backdrop>
    </div>
  );
}
