import React, { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../../store';

const RequireAuth = ({ children }) => {
  // const navigate = useNavigate();
  // const { userData } = useStore();
  // useEffect(() => {
  //   if (!userData?.isUserAuthenticated) {
  //     navigate('/');
  //   }
  // }, [userData?.isUserAuthenticated, navigate]);

  // return userData?.isUserAuthenticated ? children : null;

  return children;
};

export default memo(RequireAuth);
