import create from "zustand";
import { persist, devtools } from "zustand/middleware";

// Common store configuration
const storeConfig = (set) => ({
  userData: null,
  brands: [],
  shots: [],
  shotsApplied: [],
  shotsFavourited: [],
  shotsWon: [],
  cities: [],
  educations: [],
  registeredMail: "",
  activities:[],
  ageRanges:null,
  forgotPassword: {
    mail: null,
    isForgotPassword: false,
    otp: null,
  },
  profileDetail: null,
  loading: false,
  alphaActive: {},
  setLoading: (data) => set({ loading: data }),
  setRegisteredMail: (data) => set({ registeredMail: data }),
  setForgotPassword: (data) =>
    set((state) => ({
      forgotPassword: {
        ...state.forgotPassword,
        ...data,
      },
    })),
  setProfileDetail: (data) => set({ profileDetail: data }),
  setUserData: (data) => set({ userData: data }),
  setBrands: (data) => set({ brands: data }),
  setAlphaActive: (data) => set({ alphaActive: data }),
  setShots: (data) => set({ shots: data }),
  setShotsApplied: (data) => set({ shotsApplied: data }),
  setShotsFavourited: (data) => set({ shotsFavourited: data }),
  setShotsWon: (data) => set({ shotsWon: data }),
  setCities: (data) => set({ cities: data }),
  setEducations: (data) => set({ educations: data }),
  setActivities:(data) => set({activities:data}),
  setAgeRanges:(data) => set({ageRanges:data}),
  reset: () =>
    set(() => ({
      userData: null,
      brands: [],
      shots: [],
      cities: [],
      educations: [],
      registeredMail: "",
    })),
});

const useStore = create(
  process.env.NODE_ENV === "development"
    ? devtools(
        persist(storeConfig, {
          name: "userData", // name of the item in the storage (must be unique)
          getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
        }),
        { name: "ZustandStore" } // Optionally, you can name your store for easier identification in the DevTools
      )
    : persist(storeConfig, {
        name: "userData", // name of the item in the storage (must be unique)
        getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
      })
);

export default useStore;
