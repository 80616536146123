import { lazy } from "react";

const routes = [
  {
    path: "/login",
    exact: true,
    element: lazy(() => import("../pages/account/Login")),
  },
  {
    path: "/forgotten-password",
    element: lazy(() => import("../pages/account/ForgotPassword")),
  },
  {
    path: "/reset-password",
    element: lazy(() => import("../pages/account/ResetPassword")),
  },
  {
    path: "/sign-up",
    element: lazy(() => import("../pages/account/SignUp")),
  },
  {
    path: "/verify-otp",
    element: lazy(() => import("../pages/account/verifyOtp")),
  },
  {
    path: "*",
    element: lazy(() => import("../components/NotFound")),
  },
  // {
  //   path: "/.well-known/apple-app-site-association",
  //   element: lazy(() => import("../pages/deepLink/index")),
  // },
  // {
  //   path: "/.well-known/assetlinks.json",
  //   element: lazy(() => import("../pages/deepLink/Android")),
  // },
];

export default routes;
