// PrivateLayout.js
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import MenuBar from "../menu/index";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Box } from "@mui/material";
import Footer from "../footer";

const PrivateLayout = () => {

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {/* <CssBaseline /> */}
      <MenuBar />
      {/* <Toolbar /> */}
      <Box component="main" sx={{ flexGrow: 50, p: 0 }}>
        <Outlet />
      </Box>
      <Footer/>
    </Box>
  );
};

export default PrivateLayout;
