import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import RequireAuth from "../components/requreAuth/index";
import PrivateLayout from "../components/requreAuth/privateLayout";
import Loader from "../components/Loader";

const Pages = () => {
  return (
    <Router basename="/">
      <Routes>
        {PublicRoutes?.map((route, index) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            element={
              <Suspense fallback={<div><Loader open={true}/></div>}>
                <route.element />
              </Suspense>
            }
          />
        ))}
        <Route
          element={
            <RequireAuth>
              <PrivateLayout />
            </RequireAuth>
          }
        >
          {PrivateRoutes?.map((route, index) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense fallback={<div><Loader open={true}/></div>}>
                  <route.element />
                </Suspense>
              }
            />
          ))}
        </Route>
      </Routes>
    </Router>
 
  );
};

export default Pages;
