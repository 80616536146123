export const settings = [
  { name: "Profile", path: "profile" },
  { name: "Logout", path: "logout" },
];

export const menuItems = [
  { name: "Shots", path: "/shots" },
  { name: "Brands", path: "/brands" },
  { name: "Cities & Regions", path: "/cities" },
  { name: "For Business", path: "/business" },
  { name: "Educational", path: "/educational" },
];

export const cardData = [
  {
    id: 1,
    image: "https://via.placeholder.com/300x200",
    title: "Talk 2 Dan 4 Media Jobs Free Event",
    provider: "Slensky",
    views: "23.2k",
  },
  {
    id: 2,
    image: "https://via.placeholder.com/300x200",
    title: "Talk 2 Dan 4 Media Jobs Free Event",
    provider: "Talk 2 Dan",
    views: "23.2k",
  },
  {
    id: 3,
    image: "https://via.placeholder.com/300x200",
    title: "Talk 2 Dan 4 Media Jobs Free Event",
    provider: "Slensky",
    views: "23.2k",
  },
];

export const GENDER = [
  { value: "m", label: "Male" },
  { value: "f", label: "Female" },
  { value: "o", label: "Other" },
];
