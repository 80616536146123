import './App.css';
import Pages from './routes';
import "./styles/custom.css"
function App() {

  return (
    <div className="App" >
      <Pages />
    </div>
  );
}

export default App;
